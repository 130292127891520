import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import cx from "classnames";

import OptionsGroup from "../../components/OptionsGroup";
import { yesNoOptions } from "../../../../commons/loan-application-constants";
import { useStyles as useStepStyles } from "../../../../utils/loan-application.styles";
import Button from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  marginTop48OnMobile: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "48px",
    },
  },
}));

const LoanBorrowIntendStep = ({ formik, handleNextStep, handlePrevStep }) => {
  const classes = useStyles();
  const stepClasses = useStepStyles();

  return (
    <Box>
      <Grid container spacing={6} xs={12} sm={7}>
        <OptionsGroup
          title="Do you intend to borrow money other than your property?"
          options={yesNoOptions}
          selectedValue={
            formik.values.loanQuiz.loanBorrowOtherThanPropertyIntend
          }
          onSelect={(value) =>
            formik.setFieldValue(
              "loanQuiz.loanBorrowOtherThanPropertyIntend",
              value
            )
          }
        />
      </Grid>
      <Grid
        container
        xs={12}
        sm={6}
        justifyContent="space-between"
        classes={{
          root: cx(
            stepClasses.navigationButtonsContainer,
            classes.marginTop48OnMobile
          ),
        }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button
          endIcon={<NavigateNextIcon />}
          onClick={handleNextStep}
          disabled={formik.values.loanQuiz.loanPurpose === ""}
        >
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default LoanBorrowIntendStep;
